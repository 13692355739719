<template>
  <div class="wap-edit-product">
    <headerBar :title="$t('bian-ji-shang-pin')" @back="back"></headerBar>

    <div class="wap-form main">
      <div class="flex-center-between form-item">
        <div class="label big">{{ $t('shi-fou-shang-jia') }}</div>
        <div>
          <vanSwitch v-model="form.isPush" size="18px"></vanSwitch>
        </div>
      </div>

      <div class="form-item">
        <div class="label">{{ $t('shang-pin-shou-jia') }}</div>
        <Field
          v-model="form.Price"
          label=""
          :placeholder="$t('qing-shu-ru-shang-pin-shou-jia')"
        >
        </Field>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('li-run-bi-li') }}</div>
        <Field
          v-model="form.percent"
          label=""
          :placeholder="$t('qing-shu-ru-li-run-bi-li-3')"
        >
          <template #button> % </template>
        </Field>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('zhe-kou-kai-shi-ri-qi') }}</div>
        <Field
          v-model="form.start"
          readonly
          label=""
          :placeholder="$t('qing-xuan-ze')"
          @click="changeStart(1)"
        ></Field>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('zhe-kou-jie-shu-ri-qi') }}</div>
        <Field
          v-model="form.end"
          readonly
          label=""
          :placeholder="$t('qing-xuan-ze-0')"
          @click="changeStart(2)"
        ></Field>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('zhe-kou-bi-li') }}</div>
        <Field
          v-model="form.discount"
          label=""
          :placeholder="$t('qing-shu-ru-li-run-bi-li-4')"
        >
          <template #button> % </template>
        </Field>
      </div>

      <Button type="info" class="submit-btn" @click="submit">{{
        $t('ti-jiao')
      }}</Button>
      <Button
        type="danger"
        class="submit-btn mt-20"
        v-if="isEditOne"
        @click="deleteProduct"
        >{{ $t('shan-chu') }}</Button
      >
    </div>

    <Popup v-model="showPopup" position="bottom">
      <DatetimePicker
        v-model="dateTime"
        type="date"
        title="$t('xuan-ze-nian-yue-ri')"
        @cancel="cancel"
        @confirm="chooseDate"
      ></DatetimePicker>
    </Popup>
  </div>
</template>
<script>
import {
  Button,
  DatetimePicker,
  Popup,
  Switch as vanSwitch,
  Field,
  Dialog
} from 'vant'
import headerBar from '@/components/header'
import { editShopProduct, batchEditStatus, removeProduct } from '@/api/shop'
import moment from 'moment'
export default {
  components: {
    Button,
    headerBar,
    DatetimePicker,
    vanSwitch,
    Popup,
    Field
  },
  data() {
    return {
      form: {
        isPush: true,
        start: '',
        end: '',
        Price: ''
      },
      showPopup: false,
      dateTime: '',
      timeFlag: '',
      editId: '',
      isEditOne: false
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    }
  },
  mounted() {
    this.editId = this.$route.query.ids
    let status = this.$route.query.status
    this.form.isPush = status == 1
    this.isEditOne = this.$route.query.type == 'one'
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeStart(flag) {
      this.timeFlag = flag
      this.showPopup = true
      let date = flag == 1 ? this.form.start : this.form.end
      this.dateTime = date ? new Date(date) : new Date()
    },
    cancel() {
      this.showPopup = false
    },
    chooseDate(e) {
      if (this.timeFlag == 1) {
        this.form.start = moment(e).format('YYYY-MM-DD')
      } else {
        this.form.end = moment(e).format('YYYY-MM-DD')
      }
      this.showPopup = false
    },
    async submit() {
      let ids = this.editId.split(',')
      let index = 0
      for (let i = 0; i < ids.length; i++) {
        let id = ids[i]
        //上架、下架
        batchEditStatus({
          IDs: [parseFloat(id)],
          Status: this.form.isPush ? 1 : 2
        })
        let res = await editShopProduct({
          ID: parseFloat(id),
          Price: parseFloat(this.form.Price),
          BusinessProductSpecificationsRelation: []
        })
        if (res.code == 0) {
          index += 1
          if (index == ids.length) {
            this.init()
            this.selectRows = []
          }
          continue
        } else {
          this.$toast(res.msg)
          break
        }
      }
    },
    deleteProduct() {
      Dialog.confirm({
        title: this.$t('que-ren'),
        message: this.$t('que-ren-shan-chu-0')
      }).then(() => {
        removeProduct({
          ID: parseFloat(this.editId)
        }).then((res) => {
          if (res.code == 0) {
            this.$toast(this.$t('shan-chu-cheng-gong'))
            setTimeout(() => {
              this.back()
            }, 1500)
          } else {
            this.$toast(res.msg)
          }
        })
      })
    }
  }
}
</script>